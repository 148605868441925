// cSpell:ignore GALLAF

const brandsFeatures = {
  SOEUR: {
    brandId: '6475bef621946e928aa72683',
    auth_shops: true,
    step1_atShopRdv: true,
    step6_noMinOrderAmount: true,
    infos_hideCardBlock: true,
    infos_noShopLabel: false,
  },
  MARIANNE: {
    brandId: '61c5a33dd63f820041ce81ee',
    auth_shops: true,
    step1_atShopRdv: true,
    step6_noMinOrderAmount: true,
    infos_hideCardBlock: true,
    infos_noShopLabel: false,
  },
  MARIA_DE_LA_ORDEN: {
    brandId: '63974a65f67f960036303306',
    auth_shops: true,
    step1_atShopRdv: false,
    step6_noMinOrderAmount: true,
    infos_hideCardBlock: true,
    infos_noShopLabel: false,
    infos_codePromoButton: 'PLATFORM_MDLO_20230102',
    infos_codePromoButtonLabel: 'Frais de déplacement offerts',
    infos_codePromoDefault: true,
  },
  MOKKI: {
    brandId: '637250fbd6c21900363cc2fb',
    auth_shops: true,
    step1_atShopRdv: true,
    step6_noMinOrderAmount: true,
    infos_hideCardBlock: true,
    infos_noShopLabel: false,
  },
  GALERIES_LAFAYETTE_LYON: {
    brandId: '6228ad28a9e46200425dca9b',
    auth_shops: true,
    retailShopId: '622b7eb00e9a55199d154bfb',
    step6_noMinOrderAmount: true,
    infos_shopInput: true,
    infos_noShopLabel: true,
  },
  '5ASEC': {
    brandId: '620d0883e862fb003dbcfb34',
    auth_shops: true,
    step1_atShopRdv: true,
    step3_atShopPromoCode: '5ASEC_PLATFORM_20220322',
    step6_noMinOrderAmount: true,
    step6_secondPromoCode: true,
    infos_hideCardBlock: true,
    infos_noShopLabel: false,
  },
  ZARA: {
    brandId: '620bd746061206003659df01',
    auth_shops: true,
    step1_atShopRdv: true,
    step6_noMinOrderAmount: true,
    step6_secondPromoCode: true,
    infos_hideCardBlock: true,
    infos_noShopLabel: false,
  },
  AIGLE: {
    brandId: '60a293ca4297390036f085eb',
    auth_shops: true,
    step3_atShopPromoCode: 'AIGLE_PLATFORM_20211104',
    step1_atShopRdv: true,
    step6_noMinOrderAmount: true,
    step6_secondPromoCode: true,
    infos_hideCardBlock: true,
    infos_noShopLabel: false,
  },
  PRINTEMPS: {
    step6_noMinOrderAmount: true,
    infos_hideCardBlock: true,
    infos_noShopLabel: false,
  },
  SEQUOIA_PRESSING: {
    step6_noMinOrderAmount: true,
    infos_hideCardBlock: true,
    infos_noShopLabel: false,
  },
  TILLI_RETAIL: {
    brandId: '5f048f96ab2bce0024fc5038',
    clothesSelector_clothes: ['pant', 'mant', 'robe', 'mari', 'haut', 'jupe', 'pull', 'ling', 'acce'],
    step6_noMinOrderAmount: true,
    infos_hideCardBlock: true,
  },
  LA_MARIEE_CAPRICIEUSE: {
    brandId: '5e981bf11966bd002c69c7fe',
    auth_shops: true,
    clothesSelector_clothes: ['robe', 'mari'],
    step6_noMinOrderAmount: true,
    step6_showPromoCodeField: false,
    infos_hideCardBlock: true,
    infos_codePromoButton: 'PLATFORM_LMC_20200524',
    infos_codePromoButtonLabel: '100% offerts',
  },
  SESSUN: {
    brandId: '5cbc75ecc977b5002c30cf70',
    auth_shops: true,
    clothesSelector_hideDeco: true,
    clothesSelector_hideMari: true,
    alterations_hideSkipOption: true,
    step1_atShopRdv: true,
    step6_noMinOrderAmount: true,
    step6_showPromoCodeField: false,
    infos_hideCardBlock: true,
    infos_noShopLabel: true,
    infos_codePromoButtons: [
      { code: 'SESSUN_PLATFORM_10_0421', value: 10 },
      { code: 'SESSUN_PLATFORM_20_0421', value: 20 },
      { code: 'SESSUN_PLATFORM_100_0421', value: 100, type: 'PERCENT' },
    ],
  },
  GALERIES_LAFAYETTE: {
    home_showTutorial: 'https://www.youtube-nocookie.com/embed/CqAvDuxQ3i4?rel=0&amp;showinfo=0',
    clothesSelector_hideDeco: true,
    clothesSelector_hideMari: true,
    infos_hideCardBlock: true,
    infos_codePromoButtons: [
      { code: 'GALLAF1903128', value: 8 },
      { code: 'GALLAF19031220', value: 20 },
      { code: 'GALLAF19031232', value: 32 },
    ],
    step6_showPromoCodeField: false,
    step6_noMinOrderAmount: true,
    alterations_hideSkipOption: true,
    alterations_hideEmbroidery: true,
    step3_noCurtains: true,
  },
  SEZANE: {
    brandId: '5ad0ba100c00270025a7bd4b',
    auth_shops: true,
    home_showTutorial: 'https://www.youtube-nocookie.com/embed/ch46qcLn7TY?rel=0&amp;showinfo=0',
    clothesSelector_hideDeco: true,
    clothesSelector_hideMari: true,
    alterations_hideSkipOption: true,
    step6_noMinOrderAmount: true,
    infos_hideCardBlock: true,
    infos_noShopLabel: true,
  },
  MADURA: {
    home_hideHeaderTitle: true,
    step2_showTitleWithoutCloth: true,
    step2_renderClothList: false,
    step2_noLining: true,
    step2_openCurtainCategory: true,
    step3_curtainsOnly: true,
    step4_showCurtainWarning: true,
    step6_showPromoCodeField: false,
  },
  brandsDefault: {
    home_hideBeTilliste: true,
    home_showFullPage: false,
    home_showBrandFooter: true,
    order_thirdPersonLabels: true,
    step5_skip: true,
    infos_hideCardBlockOnlyWhenNewCustomer: false,
    step6_allowShadowAccount: true,
    step6_allowOrderWithoutCard: true,
    step6_skip3ds: true,
    infos_noShopLabel: false,
    clothesSelector_hideMari: false,
  },
  default: {
    auth_shops: false,
    step1_atShopRdv: false,
    step1_atShopRdvWithEstimate: false,
    // TODO: write description for each feature
    // TODO: do not use numbers for steps but their title
    home_hideBeTilliste: false, // hide the BeTilliste link in menu bar
    home_showFullPage: true,
    home_showBrandFooter: false,
    home_hideHeaderTitle: false,
    home_showTutorial: false,
    faq_version: 'default',
    order_thirdPersonLabels: false, // use "Le prénom de votre client" instead of "Votre prénom"
    progressBar_hide: false,
    // clothes to display in step 1
    clothesSelector_clothes: ['pant', 'mant', 'robe', 'haut', 'jupe', 'pull', 'ling', 'acce'],
    clothesSelector_hideDeco: false, // DEPRECATED
    clothesSelector_hideMari: false, // DEPRECATED
    alterations_hideSkipOption: false,
    alterations_hidePrice: false,
    alterations_showSimpleAsOffered: false,
    alterations_hideEmbroidery: false,
    step2_showTitleWithoutCloth: false,
    step2_renderClothList: true,
    step2_noLining: false,
    step2_openCurtainCategory: false,
    step3_curtainsOnly: false,
    step3_noCurtains: false,
    step3_atShopPromoCode: '',
    fabrics_skip: false,
    step4_showCurtainWarning: false,
    step5_skip: false,
    step6_allowShadowAccount: false,
    step6_allowOrderWithoutCard: false,
    step6_showPromoCodeField: true,
    step6_skip3ds: false,
    step6_noMinOrderAmount: false,
    step6_secondPromoCode: false,
    infos_hideCardBlock: false,
    infos_codePromoButtons: false,
    infos_hideCardBlockOnlyWhenNewCustomer: true,
    infos_hideOrderSummary: false,
    infos_validateOrderWording: false,
    infos_code_promo_button: false,
    infos_noShopLabel: false,
    infos_shopInput: false,
    success_onTheSpotEvent: false,
  },
};

const acceptedTypes = ['boolean', 'string', 'object'];

// TODO: write test to ensure each feature for a brand has a default value
export default function featureSwitch(featureName) {
  // !brand => tilli website (default)
  const brand = process.env.GATSBY_BRAND || 'default';

  if (acceptedTypes.includes(typeof brandsFeatures[brand][featureName])) {
    return brandsFeatures[brand][featureName];
  }

  if (acceptedTypes.includes(typeof brandsFeatures.brandsDefault[featureName])) {
    return brandsFeatures.brandsDefault[featureName];
  }
  return brandsFeatures.default[featureName];
}
